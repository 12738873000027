import React, { useState } from 'react'
import PropTypes from 'prop-types'
import placeholderImage from 'assets/images/placeholder.png'
import ReadMoreButton from 'components/ReadMoreButton'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import Image from 'components/Image'
import { unescapeHtml } from 'data/helpers/html'
import { getLoadedImagesArray, getNumberArray } from 'data/helpers/carousel'
import { useOnScreen } from 'data/hooks/useOnScreen'
import { RestArticle } from 'data/types/article'

const CarouselMagazine = ({ articles = [] }: { articles: RestArticle[]}) => {
  const componentRef = React.useRef(null)

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const buttonClasses = 'absolute visible !w-40 !h-40 sm:!w-50 sm:!h-50 top-[145px] sm:top-[185px] md:top-1/2 -mt-20 md:-mt-25'

  const pagination = {
    type: 'bullets' as const,
    clickable: true,
    clickableClass: 'carousel-clickable-magazine',
    bulletClass: 'carousel-bullet',
  }

  const imagesEitherSide = 2
  const imagesLength = articles.length
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([...numberArray.slice(0, imagesEitherSide + 1)])

  const { initialLoad } = useOnScreen(componentRef)

  return (
    <div ref={componentRef}>
      {
        initialLoad
        && <Swiper
          modules={[Pagination, Navigation]}
          className="max-w-[1480px] px-0 sm:px-20"
          slidesPerView={1}
          spaceBetween={10}
          pagination={pagination}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper: SwiperClass) => {
            setTimeout(() => {
              if (swiper.params.navigation && typeof(swiper.params.navigation) !== 'boolean') {
                // Timeout until prevEl & nextEl refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }

              // Re-initialise navigation
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
            })
          }}
          onSlideChange={(swiper: SwiperClass) => {
            if (loadedImages.length !== imagesLength) {
              setLoadedImages(getLoadedImagesArray(
                swiper.realIndex,
                imagesEitherSide,
                imagesLength,
                numberArray,
                loadedImages,
              ))
            }
          }}
          observer={true}
          centeredSlides={true}
        >
          <>
            {articles != undefined
              ? articles.map((article, index) =>
                <SwiperSlide className="xl:flex xl:justify-center" key={index}>
                  <div className="flex flex-col w-full md:flex-row md:mx-0 lg:max-w-[1280px] gap-x-50 xl:gap-x-100 justify-center px-20 md:px-0">
                    <div className="flex justify-center w-full lg:flex-col md:pl-30">
                      <div className="relative swiper-lazy object-cover rounded-4 w-full h-[270px] sm:shadow-image-magazine sm:mt-48
                            sm:w-[530px] sm:h-[300px]
                            md:w-[360px] md:h-[440px]
                            lg:w-[450px] lg:h-[550px]
                            xl:w-[530px] xl:h-[660px]
                            sm:ml-30">
                        {loadedImages.includes(index)
                          && <Image
                            src={article?.thumbnail?.src ?? placeholderImage}
                            alt={article?.thumbnail?.alt_text ?? 'Placeholder Image'}
                            className="absolute inset-0 object-cover rounded-4"
                            layout="fill"
                            priority
                          />
                        }
                      </div>
                      <p className="hidden mt-10 lg:block text-grey-500 tracking-1/2 ml-30">
                        {
                          article.categories.length
                            ? <><span className="uppercase">{unescapeHtml(article.categories[0].name)}</span>:&nbsp;</>
                            : null
                        }
                        Published on {article.created_at}
                      </p>
                    </div>

                    <div className="flex justify-center w-full md:my-auto md:justify-start md:pr-50 xl:pr-0">
                      <div className="flex flex-col justify-center items-start gap-20 mt-60 md:my-0 max-w-[546px] px-20 sm:px-0">
                        <p className="font-bold uppercase text-grey-750 text-14 tracking-1/2 leading-20">Our Magazine</p>
                        <h2 className="font-serif font-bold text-grey-900 leading-title tracking-title text-32 sm:text-48" dangerouslySetInnerHTML={{ __html: article.title }} />
                        <div className="text-grey-750 text-14 max-w-[510px]" dangerouslySetInnerHTML={{ __html: article.excerpt }} />
                        <ReadMoreButton href={`/magazine/${article.slug}`} className="mt-10 w-[180px]">
                          Read More
                        </ReadMoreButton>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>,
              )
              : null}
          </>
          <div ref={navigationPrevRef} className={`swiper-button-prev left-10 lg:left-15 xl:left-20 ${buttonClasses}`}></div>
          <div ref={navigationNextRef} className={`swiper-button-next right-10 lg:right-15 xl:right-20 ${buttonClasses}`}></div>
        </Swiper>

      }
    </div>
  )
}

CarouselMagazine.propTypes = {
  articles: PropTypes.array,
}

export default CarouselMagazine
