import PropTypes from 'prop-types'
import CarouselMagazine from 'components/carousels/CarouselMagazine'
import { gql, useQuery } from 'urql'
import { isServer } from 'data/helpers/ssr'
import MagazineCarouselSection from './magazine/MagazineCarouselSection'

const MagazineSection = ({ country, region, subregion }) => {
  const [{ data, fetching }] = useQuery({
    query: gql`
      query MagazineArticles ($filters: MagazineArticlesFilterInput) {
        magazineArticles(first: 9, filters: $filters) {
          data {
            id
            thumbnail {
              src
              alt_text
            }
            categories {
              id
              name
            }
            created_at
            title
            excerpt
            slug
          }
        }
      }
    `,
    variables: {
      filters: country
        ? {
          country: country,
          region: region,
          subregion: subregion,
        }
        : undefined,
    },
    pause: isServer,
  })

  if (isServer || fetching) {
    return <></>
  }

  return (
    <>
      <div className="antialiased my-75 lg:my-[125px] hidden lg:block">
        <CarouselMagazine articles={data?.magazineArticles?.data ?? []} />
      </div>

      <div className="lg:hidden mb-18">
        <MagazineCarouselSection
          subheading="Our Magazine"
          className={{
            container: 'my-50',
          }}
        />
      </div>
    </>
  )
}

MagazineSection.propTypes = {
  country: PropTypes.string,
  region: PropTypes.string,
  subregion: PropTypes.string,
}

export default MagazineSection
